// paper & background
$paper: #ffffff;

// primary
$primaryLight: #19b7f13d;
// $secondaryMain: #009688;
// $secondaryDark: #02c4b0;
$primaryMain: #0E2D6D;
$primaryDark: #40c6fd;
$primary200: #9fa8da;
$primary800: #283593;

// secondary
$secondaryLight: #95edf530;
$secondaryMain: #40c6fd;
$secondaryDark: rgba(25, 182, 241, 0.08);
$secondary200: #9fa8da;
$secondary800: #283593;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;


@mixin themebtn{
    background: $primaryMain;
    color: $white;
    border: 1px solid $primaryMain;
    min-width: 100px;
    padding: .2rem .71rem;

    &:hover {
      background-color: $secondaryMain;
      border-color: $secondaryMain;
      letter-spacing: 2px;
      min-width: 120px;
    }
}



















$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;
$white: #ffff;
// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: rgba(15, 23, 42, 0.9); // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #eeedfd;
$darkPrimaryMain: #7267ef;
$darkPrimaryDark: #6a5fed;
$darkPrimary200: #b9b3f7;
$darkPrimary800: #554ae8;

// secondary dark
$darkSecondaryLight: #212946;
$darkSecondaryMain: #67c4ef;
$darkSecondaryDark: #6a5fed;
$darkSecondary200: #b9b3f7;
$darkSecondary800: #554ae8;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// Border Colors ...............................
$borderColor: rgba(236, 238, 246, 1);
$borderWhite:rgba(51, 65, 85, 1);

$borderMenu: rgba(0, 196, 230, 0.192);

$bgDark:rgba(2, 6, 23, 1);
$bgGrey:rgba(219, 218, 219, 0.445);


// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;

    //borderColor

    borderColor : $borderColor;
    borderWhite : $borderWhite;
    borderMenu : $borderMenu;

    bgDark:$bgDark;
    bgGrey:$bgGrey;
}
