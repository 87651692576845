// color variants
@import "_theme7.module.scss";

// third-party
@import " ~react-perfect-scrollbar/dist/css/styles.css";
@import " ~slick-carousel/slick/slick.css";
@import " ~slick-carousel/slick/slick-theme.css";

@import " ~react-18-image-lightbox/style.css";

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey300;
  opacity: 0.7;
}
.avatarHead{
  color: $white;
  font-weight: 600;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

.logoSize {
  width: 65%;
  margin: 1rem;
  @media screen and (min-width: 900px) {
    width: 95%;
    margin: 0;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
  overflow: hidden;
  .slider {
    .slide {
      opacity: 0.5;
      display: flex;
      justify-content: center;
      .custom-slider {
        gap: 40px;
      }
      &.selected {
        opacity: 1;
        .custom-slider {
          display: flex;
          flex-direction: column;
        }
      }
      &:not(.selected) {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
  }
}

.project-info {
  .project-slider {
    .slick-list {
      padding: 0 !important;
    }
  }
  .slick-slide {
    opacity: 0.05;
  }
  .slick-active {
    opacity: 0.2;
  }
  .slick-current {
    opacity: 1 !important;
    .MuiTypography-root {
      color: $primaryMain;
    }
  }
}

.ril__inner {
  direction: ltr;
}

.ReactModal__Overlay {
  z-index: 99999 !important;
}

.css-1ntimkv-MuiTypography-root {
  margin-bottom: 13px;
  margin-left: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.334em;
  font-family: "Roboto", sans-serif;
  color: #f44336;
}

// ==============================|| HOME ||============================== //

.slick-disabled {
  opacity: 0;
}
.slick-track {
  margin: 0;
}
.slick-next {
  right: 33px;
  position: absolute;
  top: 48%;
}
.slick-prev {
  left: -12px;
}

.slick-arrow:before {
  font-size: 3rem;
  // opacity: .3;
}
.slick-arrow {
  z-index: 999;
}

.sliderDivMain {
  // padding-left: 0.581rem;
  // padding-right: 0.581rem;

  // padding-top: 1.1rem;
  // padding-bottom: 0.581rem;
  position: relative;
  // margin-bottom: 1rem;
  box-shadow:  0 6.4px 14.4px 0 rgba(0, 0, 0, 0.075), 0 1.2px 3.6px 0 rgba(0, 0, 0, .1);
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 48%;
  }
}
.sltdermainViewMore {
  @extend .sliderDivMain;
}
.shadow{
  box-shadow:  0 6.4px 14.4px 0 rgba(0, 0, 0, 0.075), 0 1.2px 3.6px 0 rgba(0, 0, 0, .1);

}

//-----------------Slider

.sliderDivMain {
  // margin-bottom: 1.5rem;

  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 20px;
  
   

    .card {
      position: relative;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      height: 199px;
      perspective: 500px;
      // margin: .851rem;
      overflow: hidden;
      // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      // border: 1px solid;\
      box-shadow: none;
      cursor: pointer;
      // &:hover{
      //   box-shadow: 1px 1px 9px #ffffff79;
      // }

      @media (min-width: 900px) and (max-width: 980px) {
        width: 90%;
        min-width: 265px;
      }
      @media (min-width: 2200px) {
        min-width: 100%;
      }

      .imgBx {
        width: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;
        height: 100%;
        padding-bottom: 0.71rem;

        img {
          object-fit: cover;
          width: 100%;
          min-height: 340px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        &:hover img {
          transform: scale(1.02);
          box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.4),
            0px 0px 30px rgba(255, 255, 255, 0.6);
        }

        /* Shine overlay */
        &::before {
          content: "";
          position: absolute;
          top: 0px;
          left: -100%;
          width: 90%;
          height: 95%;
          border-radius: 20px;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: skewX(-20deg);
          transition: none;
          z-index: 2;
          // padding: 0 10px;
        }

        &:hover::before {
          animation: combined-shine 1.2s ease-in-out forwards;
        }

        /* Glowing pulse effect */
        &:hover {
          animation: glow-pulse 1.5s ease-in-out infinite;
        }

        @keyframes combined-shine {
          0% {
            left: -100%;
          }
          100% {
            left: 100%;
          }
        }

        @keyframes glow-pulse {
          0% {
            box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4),
              0px 0px 40px rgba(255, 255, 255, 0.6);
          }
          50% {
            box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.6),
              0px 0px 50px rgba(255, 255, 255, 0.8);
          }
          100% {
            box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4),
              0px 0px 40px rgba(255, 255, 255, 0.6);
          }
        }
      }

      // .imgBx {
      //   width: 100%;
      //   cursor: pointer;

      //   img {
      //     object-fit: cover;
      //     width: 100%;
      //     min-height: 340px;
      //   }
      //   img {
      //     -webkit-mask-image: linear-gradient(45deg,#e9e5e5 25%,rgba(236, 231, 231, 0.2) 50%,#ffffff 75%);
      //     mask-image: linear-gradient(45deg,#ffffff 25%,rgba(255, 255, 255, 0.2) 50%,#ffffff 75%);
      //     -webkit-mask-size: 800%;
      //     mask-size: 800%;
      //     -webkit-mask-position: 0;
      //     mask-position: 0;
      //     filter: blur(1);
      //   }

      //   img:hover {
      //     transition: mask-position 2s ease,-webkit-mask-position 1s ease;
      //     -webkit-mask-position: 120%;
      //     mask-position: 120%;
      //     opacity: 1;
      //   }

      // }
    }
    .smallCard {
      @extend .card;
      min-width: 100%;
      .imgBx {
        width: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;

        img {
          object-fit: cover;
          width: 100%;
          min-height: 340px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        &:hover img {
          transform: scale(1.02);
          box-shadow: 0px;
        }

        /* Shine overlay */
        &::before {
          content: "";
          position: absolute;
          top: -4px;
          left: -100%;
          width: 90%;
          height: 75%;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: skewX(-20deg);
          transition: none;
          z-index: 2;
          // padding: 0 10px;
        }
      }
    }
  }
 

  .container .card .content {
    position: absolute;
    border-radius: 20px;
    // border-bottom: 1px solid;
    padding: 1rem;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    max-height: 350px; // Initial height when not hovered
    display: flex;
    justify-content: center;
    align-items: start;
    z-index: 10;
    flex-direction: column;
    backdrop-filter: blur(7px);
    box-shadow: none !important;
    background: linear-gradient(
      to bottom,
      rgba(1, 4, 34, 2%),
      rgba(0, 3, 37, 0.993)
    );
    transition: max-height 0.5s ease, bottom 0.5s ease;
    transition-delay: 0.3s;
    .iconCard {
      font-size: 1.5em;
      // transform: rotate(-90deg);
      background: $white;
      color: $primaryMain;
      border-radius: 7px;
      width: 32px;
      height: 22px;
      opacity: 0.5;
      border: 1px solid $primaryMain;
      cursor: pointer;
    }
    .badge {
      display: none;
      background: $darkPaper;
      padding: 0.2rem 0.3rem;
      color: $grey50;
      border-radius: 5px;
      font-size: 0.78em;
      outline: 0.5px solid $grey700;
      margin: 0.23rem 0.2rem;
      &:first-child {
        margin-left: 0;
      }
    }

    .cardTextH {
      display: block;
      font-size: 1.2em;
      transition: 0.3s;
      color: $white;
      font-weight: 500;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 1;
      cursor: pointer;
    }

    .cardDesc {
      display: none;
      font-size: 1em;
      font-weight: 400;
      width: 100%;
      overflow: auto;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      max-height: 50px;
      min-height: 40px;
      &::-webkit-scrollbar {
        width: 6px; /* Smaller width */
        height: 6px; /* Smaller height for horizontal scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background: #c4c2c2; /* Darker thumb color */
        border-radius: 3px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f15e; /* Light track color */
        border-radius: 3px;
      }
    }
    .lastVisitDiv {
      color: $white;
      font-size: 0.91em;
      margin-top: 0.5rem;
      display: none;
    }
    .editDiv {
      display: none;
      cursor: pointer;
    }
  }
  .editDiv {
    position: absolute;
    z-index: 999;
    bottom: 18px;
    right: 20px;
    color: $white;

    .editBtnsGroup {
      transition: opacity 0.3s ease-in, visibility 0s linear 0.3s; // Show instantly, hide after delay
      position: absolute;
      z-index: 999;
      background: $white;
      padding: 0;
      bottom: 7px;
      right: 0px;
      border-radius: 6px;
      flex-direction: column;

      button {
        min-width: 20px;
        min-height: 20px;
        border: 1px solid $white;

        &:hover {
          border: 1px solid $primaryMain;
        }
      }
    }

    // Show immediately on hover

    // Hide with a delay when leaving
    &:not(:hover) .editBtnsGroup {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0.8s; // Delays hiding by 0.5s
    }
  }

  .container .card:hover .content {
    max-height: 250px; // Adjust based on content height you want to reveal
    transition-delay: 0s;

    .badge {
      opacity: 1;
      background: $secondaryLight;

      display: flex;
      &:first-child {
        margin-left: 0;
      }
    }

    .cardTextH {
      display: block;
    }

    .cardDesc {
      @extend .cardDesc;
      display: block;
    }
    .iconCard {
      opacity: 1;
    }
    .lastVisitDiv {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 0.51rem;
    }
    .editDiv {
      display: block;
    }
    .viewMoreBtn {
      display: flex;
    }
  }
  .verticalCard {
    @extend .card;
    min-width: 100%;
    width: 100%;
    min-height: 419px;
    min-width: 490px;

    .content {
      @extend .content;
      .cardTextH {
        display: block;
        transition: 0.3s;
        color: $white;
        font-weight: 500;
        width: 100% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 1;
        cursor: pointer;
        font-size: 1.72em !important;
        font-weight: 700 !important;
      }
      .cardDesc {
        display: block !important;
      }
      .viewMoreBtn {
        // @include themebtn;
        margin: 1rem 0;
        padding: 0.25rem 0.71rem;
        display: none;
        background: $white;
        color: $primaryMain;
        svg {
          display: none;
        }
        &:hover {
          background: $primaryMain;
          color: $white;
          svg {
            display: block;
          }
        }
      }
    }
    .imgBx {
      @extend .imgBx;
      height: 100%;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;

      img {
        min-height: 100% !important;
        height: 100%;
        width: 100%;
      }
    }
  }
  .largeCard {
    @extend .card;
    min-width: 100%;
    width: 100%;
    min-height: 350px;
    @media screen and (min-width: 3113px) {
      min-height: 383px;
    }
    .content {
      max-height: 300px;
      height: 180px !important;
      font-size: 1em !important;
      transition: 0.3s;
      padding: 2rem !important;
      &:hover {
        opacity: 1;
        height: 190px !important;
      }

      .cardTextH,
      .cardDesc,
      .badge {
        display: block !important;
        font-size: 1em !important;
      }

      .cardTextH {
        font-size: 1.92em !important;
        font-weight: 700 !important;
      }
      .iconCard {
        font-size: 3.5em !important;
        color: $white;
        width: 48px !important;
        height: 32px !important;
      }
      .badge {
        margin: 0 0.25rem !important;
        &:first-child {
          margin-left: 0;
        }
      }

      .editDiv {
        position: absolute;
        z-index: 999;
        bottom: 30px;
        right: 40px;
        color: $white;

        .editBtnsGroup {
          transition: opacity 0.3s ease-in, visibility 0s linear 0.3s; // Show instantly, hide after delay
          position: absolute;
          z-index: 9999;
          background: $white;
          padding: 0;
          bottom: 7px;
          right: -7px;
          border-radius: 6px;
          flex-direction: column;

          button {
            min-width: 44px;
            min-height: 40px;
            border: 1px solid $white;

            &:hover {
              border: 1px solid $primaryMain;
            }
          }
        }

        // Show immediately on hover

        // Hide with a delay when leaving
        &:not(:hover) .editBtnsGroup {
          opacity: 0;
          visibility: hidden;
          transition-delay: 0.8s; // Delays hiding by 0.5s
        }
      }
    }

    .imgBx {
      @extend .imgBx;
      height: 100%;

      img {
        min-height: 100% !important;
        height: 100%;
        width: 100%;
      }
      &::before {
        content: "";
        position: absolute;
        top: -4px;
        left: -100%;
        width: 90%;
        height: 95%;
      }
    }
  }
  .mediumCard {
    @extend .card;
    min-width: 99%;
    height: 300px;
    min-height: 300px;
    .imgBx {
      width: 100%;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      padding-bottom: 1rem;

      /* Shine overlay */
      &::before {
        content: "";
        position: absolute;
        top: -4px;
        left: -100%;
        width: 90%;
        height: 75% !important;
        border-radius: 20px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.4) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: skewX(-20deg);
        transition: none;
        z-index: 2;
        // padding: 0 10px;
      }
    }

    .content {
      padding: 1rem 1.5rem !important;
      .cardDesc {
        display: block !important;
        font-size: 1.12em !important;
      }
      .cardTextH {
        font-size: 1.7em !important;
      }
      .badge {
        font-size: 0.91em !important;
        margin: 0.4rem;
        &:first-child {
          margin-left: 0;
        }
      }
      .editDiv {
        right: 25px;
      }
    }
  }

  .btnGroup {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: end;
    text-align: right;

    button {
      height: 50px;
      border-radius: 8px;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); // Smooth transition
    }

    .addNewBtn {
      background: $primaryMain;
      color: $white;
      border: 1px solid $primaryMain;
      min-width: 130px;
      height: 50px;

      &:hover {
        background-color: $secondaryMain;
        border-color: $secondaryMain;
        letter-spacing: 2px;
        min-width: 120px;
      }
    }

    .viewDetailsBtn {
      // color: $primaryMain;
      // background-color: $white;
      display: inline-flex;
      border: 1px solid $primaryMain;
      overflow: hidden;
      min-width: 40px;
      transform-origin: left; // Set origin to animate from the left side
      transition: min-width 3s cubic-bezier(0.4, 0, 0.2, 1),
        transform 3s cubic-bezier(0.4, 0, 0.2, 1);

      .textBtn {
        display: none;
      }

      &:hover {
        background-color: $secondaryLight;
        min-width: 60px;
        // color: $primaryMain;
        transition: width 0.6s ease-in-out, background-color 0.3s ease; // Slower shrink on mouse leave

        .textBtn {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }

  .leftCard {
    position: relative;
    display: flex;
    gap: 1.2rem;
    justify-content: space-between;
    align-items: center;
    // margin: 0.15rem 0.71rem;
    // margin-left: 1.2rem;
    border-radius: 20px;
    cursor: pointer;
    min-height: 130px;
    width: 100%;

    .imgBx {
      width: 30%;
      position: relative;
      cursor: pointer;

      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      img {
        height: 90px;
        width: 100px;
        object-fit: cover;
        border-radius: 9px;
        @media screen and (max-width: 1535px) {
          height: 100px;
          width: 100%;
        }
        @media (min-width: 1536px) and (max-width: 1740px) {
          height: 85px;
          width: 80px;
        }
        @media screen and (min-width: 2459px) {
          height: 85px;
          width: 100%;
        }
      }
      &::before {
        content: "";
        display: none;
        position: absolute;
        top: -4px;
        left: -100%;
        width: 90%;
        height: 95%;
      }
    }
    .content {
      width: 70%;
      position: relative;
      // margin: 1rem;
      .cardTextH {
        font-size: 1.2em;
        display: block;
        // color: $darkLevel1;
        transition: 0.3s;
        font-weight: 500;
        width: 92%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 1;
        cursor: pointer;
      }
      .cardDesc {
        // @extend .cardDesc;
        min-height: 50px;
        font-size: 0.879em;
        color: $darkLevel1;
      }

      .badge {
        // display: none;
        background: $secondaryLight;
        padding: 0.1rem 0.2rem;
        color: $darkLevel1;
        border-radius: 3px;
        font-size: 0.687em;
        outline: 0.5px solid $grey700;
        margin: 0.23rem 0.2rem;
        &:first-child {
          margin-left: 0;
        }
      }
      .editDivs {
        position: absolute;
        top: 5px;
        right: -5px;
        color: $darkPaper;
        // padding: .5rem;
        align-items: center;
        text-align: center;
        .editBtnsGroup {
          transition: opacity 0.3s ease-in, visibility 0s linear 0.3s; // Show instantly, hide after delay
          position: absolute;
          z-index: 99;
          background: $white;
          padding: 0;
          bottom: -90px;
          right: 5px;
          border-radius: 6px;
          flex-direction: column;
          border: 1px solid #ccc;

          button {
            min-width: 20px;
            min-height: 20px;
            border: 1px solid $white;

            &:hover {
              border: 1px solid $primaryMain;
            }
          }
        }
        // Hide with a delay when leaving
        &:not(:hover) .editBtnsGroup {
          opacity: 0;
          visibility: hidden;
          transition-delay: 0.8s; // Delays hiding by 0.5s
        }
      }
    }
  }
}
.sliderSmall {
  @extend .sliderDivMain;
  // padding-top: 1.5rem;
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 46%;
  }
}
// ==============================|| CHAT BOT||============================== //

.chatBotHeader {
  position: sticky;
  top: -21px;
  z-index: 99;
  // background: $white;
  padding-top: 0.3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.chatBotBody {
  margin-bottom: 0rem;
  // padding-bottom: 5rem;
  height: auto;
  // min-height: 62vh;
  height: 87vh;
  // overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  width: 100%;
  bottom: 0;
  // overflow-y: scroll;
  .chatScrollDiv {
  }
}
.chatInput {
  position: sticky;
  bottom: 0px;
  width: 100%;
  // background-color: $white;
}

#outlined-adornment-email-login,
#outlined-adornment-password-login {
  background: $white;
  // height: 25px;
  font-weight: 300;
  font-size: 1.2em;
  color: $grey700;

  &::placeholder {
    background-color: $white;
  }
}

// chatBubble.scss

@keyframes bubble-bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.chat-bubble {
  // Use your theme's secondary color
  //  background: transparent;
  padding: 0.81rem;
  margin: 3px;
  margin-left: 14px;
  width: auto;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  animation: bubble-bounce 2s infinite;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid;
  .askme {
    font-size: 1.2em;
    position: absolute;
    bottom: 5px;
    font-weight: 500;
    z-index: 99;
  }

  &:hover {
    transform: scale(1.1);
    // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: scale(0.9);
  }
}
.chat2Bubble {
  @extend .chat-bubble;
  position: fixed;
  z-index: 99;
  bottom: 110px;
  right: 60px;
}
.none {
  display: none;
}

// TYping effect with animation///////////////////////
.typing-demo {
  opacity: 0; /* Start invisible */
  animation: fadeIn 1s ease-in forwards, slideIn 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with no opacity */
  }
  to {
    opacity: 1; /* End with full opacity */
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-10px); /* Start slightly off to the left */
  }
  to {
    transform: translateX(0); /* Slide to the original position */
  }
}

// Loading Dots /////////////////

.loading-dots {
  display: inline-block;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  width: 100%;
}

.loading-dots span {
  display: inline-block;
  animation: bounce 1.5s infinite ease-in-out both;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots span:nth-child(2) {
  animation-delay: 0.3s;
}
.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}
.loading-dots span:nth-child(4) {
  animation-delay: 0.9s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.scrollDownBtn {
  position: fixed;
  z-index: 9999;
  bottom: 50px;
  margin: 0 auto;
  min-width: 50px;
  height: 40px;
  padding: 0;
  // padding: .51rem;
  border-radius: 9px;
  animation: jumpInfinite 1.5s infinite;
}
@keyframes jumpInfinite {
  0% {
    bottom: 60px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 60px;
  }
}
